
import { ref, defineComponent } from 'vue'
import * as api from '../../utils/api'
import { usePremium } from '../../utils/const'
import Menu from '../../components/menu/Hospital.vue'

export default defineComponent({
	components: {
		Menu,
	},
	data() {
		const formRef = ref(null)
		const modelRef = ref({
			pwd: '',
			rePwd: '',
			oldPwd: '',
			email: '',
			verifyCode: '',
		})
		function pwdValidate(value: string) {
			if (!value) {
				return new Error('パスワードを入力してください。')
			} else if (value.length < 8) {
				return new Error('パスワードは8文字以上入力する必要があります。')
			} else if (value.length > 99) {
				return new Error('パスワードは99文字以上入力できません。')
			} else if (!/[a-zA-Z]/.test(value)) {
				return new Error('パスワードには英字を含める必要があります。')
			} else if (!/[0-9]/.test(value)) {
				return new Error('パスワードには数字を含める必要があります。')
			} else if (!/^[a-zA-Z0-9^$*.[\]{}()?"!@#%&/\\,><':|_~`]{8,99}$/.test(value)) {
				return new Error('パスワードに使用できない文字が含まれています。')
			}
			return true
		}
		return {
			usePremium,
			formRef,
			model: modelRef,
			univName: '',
			loading: false,
			mode: 'change',
			change: 'password',
			success: false,
			loadingResend: false,
			err: '',
			pwdValidate,
			rules: {
				oldPwd: [
					{
						required: true,
						validator(rule: any, value: string) {
							return pwdValidate(value)
						},
						trigger: ['input', 'blur'],
					},
				],
				pwd: [
					{
						required: true,
						validator(rule: any, value: string) {
							return pwdValidate(value)
						},
						trigger: ['input', 'blur'],
					},
				],
				rePwd: [
					{
						required: true,
						validator(rule: any, value: string) {
							if (value !== modelRef.value.pwd) return new Error('パスワードが一致しません。')
							return true
						},
						trigger: ['input', 'blur'],
					},
				],
			},
		}
	},
	mounted: function () {
		document.title = 'アカウント設定 - Vets Index'
		api.refresh()
	},
	methods: {
		post: async function () {
			let param: any = {
				role: 'hospital',
			}
			let endpoint = '/v1/account/hospital/update_credential'
			if (this.change === 'password') {
				param = {
					oldPassword: this.model.oldPwd,
					newPassword: this.model.pwd,
					...param,
				}
			}
			if (this.change === 'email' && this.mode === 'change') {
				param = {
					email: this.model.email,
					...param,
				}
			}
			if (this.change === 'email' && this.mode === 'verify') {
				param = {
					code: this.model.verifyCode,
					email: this.model.email,
					...param,
				}
				endpoint = '/v1/account/verify'
			}
			try {
				this.success = false
				this.loading = true
				const pwd = this.change === 'password' ? this.pwdValidate(this.model.pwd) : true
				if (pwd !== true) throw pwd
				const data = await api.post(endpoint, param)
				if (this.change === 'email' && this.mode === 'change' && data.success) {
					this.mode = 'verify'
					this.loading = false
				} else if (data.success) {
					this.success = true
					this.loading = false
					this.model.pwd = ''
					this.model.rePwd = ''
					this.model.oldPwd = ''
					this.model.email = ''
					this.model.verifyCode = ''
					this.mode = 'change'
				} else {
					this.loading = false
					console.error(data)
					if (data.message) this.err = data.message
				}
			} catch (e: any) {
				this.loading = false
				this.err = e.message || e.toString()
				console.log(this.err)
				console.error(e)
			}
		},
		resend: async function () {
			const param = {
				username: this.model.email,
				role: 'hospital',
			}
			try {
				this.loadingResend = true
				const data = await api.postWithoutToken('/v1/account/resend_code', param)
				if (data.success) {
					this.loading = false
				} else {
					this.loadingResend = false
					if (data.message) this.err = data.message
				}
			} catch (e: any) {
				this.loadingResend = false
				this.err = e.message || e.toString()
				console.log(this.err)
				console.error(e)
			}
		},
		logout: function () {
			localStorage.clear()
			location.href = '/'
		},
	},
})
